import { createGetKcContext } from "keycloakify/account";

export type KcContextExtension =
	| { pageId: "account.ftl" } 
	| { pageId: "password.ftl" }
	;

export const { getKcContext } = createGetKcContext<KcContextExtension>({
});

export const { kcContext } = getKcContext({
	//mockPageId: "account.ftl",
});

export type KcContext = NonNullable<ReturnType<typeof getKcContext>["kcContext"]>;
